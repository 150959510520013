<template>
  <div
    class="bg-white border-2 border-solid border-borderInput rounded-lg p-5"
    :class="{ 'opacity-50': data.status === 'DONE' }"
  >
    <!-- --------------------------------------HEALTHBOOK-------------------------------------- -->
    <template v-if="data.type === 'HEALTH-BOOK'">
      <div class="flex flex-row gap-x-4">
        <div
          class="w-[32px] h-[32px] bg-rosDok-ultra text-white rounded-full flex items-center justify-center"
          :class="bgByStatus(data.status)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1.5 2.25H6c.796 0 1.559.316 2.121.879C8.684 3.69 9 4.454 9 5.25v10.5c0-.597-.237-1.169-.659-1.591-.422-.422-.994-.659-1.591-.659H1.5V2.25zM16.5 2.25H12c-.796 0-1.559.316-2.121.879C9.316 3.69 9 4.454 9 5.25v10.5c0-.597.237-1.169.659-1.591.422-.422.994-.659 1.591-.659h5.25V2.25z"
            />
          </svg>
        </div>
        <template v-if="data.status === 'PENDING'">
          <div class="flex-1 flex flex-col gap-y-1">
            <span class="font-EffraR text-base text-black">
              {{ data.created_at | moment("DD MMMM") }}
            </span>
            <div class="flex flex-col">
              <h1 class="text-black font-EffraM text-lg">
                Carnet de santé
              </h1>
              <span class="font-EffraR text-base text-[#535353]">
                {{ data.body }}
              </span>
            </div>
            <div class="flex justify-end" v-if="data.hasOptions">
              <dok-button
                bg="action"
                custom-class="text-reddok font-EffraM flex items-center gap-x-2 uppercase"
                @click.native="
                  onHealthbookChangeStatus({ type: 'REJECTED', id: data.uid })
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13.5 4.5l-9 9M4.5 4.5l9 9"
                  />
                </svg>
                Refuser
              </dok-button>
              <dok-button
                bg="action"
                custom-class="text-dokGreen-other font-EffraM flex items-center gap-x-2 uppercase"
                @click.native="
                  onHealthbookChangeStatus({ type: 'ACCEPTED', id: data.uid })
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 4.5l-8.25 8.25L3 9"
                  />
                </svg>
                Accepter
              </dok-button>
            </div>
          </div>
        </template>
        <template v-else-if="data.status === 'ACCEPTED'">
          <div class="flex-1 flex flex-col gap-y-1">
            <span class="font-EffraR text-sm text-black">
              {{ data.created_at | moment("DD MMMM") }}</span
            >
            <div class="flex flex-col">
              <h1 class="text-black font-EffraM text-lg">
                Carnet de santé
              </h1>
              <span class="font-EffraR text-base text-[#535353]">
                {{ data.body }}
              </span>
            </div>
          </div>
        </template>
        <template v-else-if="data.status === 'REJECTED'">
          <div class="flex-1 flex flex-col gap-y-1">
            <span class="font-EffraR text-sm text-black">{{
              data.created_at | moment("DD MMMM")
            }}</span>
            <div class="flex flex-col">
              <h1 class="text-black font-EffraM text-lg">
                Carnet de santé
              </h1>
              <span class="font-EffraR text-base text-[#535353]">
                {{ data.body }}
              </span>
            </div>
          </div>
        </template>
      </div>
    </template>

    <!-- --------------------------------------APPOINTMENTS-------------------------------------- -->
    <template v-if="data.type === 'CONSULTATION'">
      <div class="flex flex-row gap-x-4">
        <div
          class="w-[32px] h-[32px] bg-rosDok-ultra text-white rounded-full flex items-center justify-center"
          :class="bgByStatus(data.status)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M8 3v2H6v4c0 2.21 1.79 4 4 4s4-1.79 4-4V5h-2V3h3c.552 0 1 .448 1 1v5c0 2.973-2.162 5.44-5 5.917V16.5c0 1.933 1.567 3.5 3.5 3.5 1.497 0 2.775-.94 3.275-2.263C16.728 17.27 16 16.22 16 15c0-1.657 1.343-3 3-3s3 1.343 3 3c0 1.371-.92 2.527-2.176 2.885C19.21 20.252 17.059 22 14.5 22 11.462 22 9 19.538 9 16.5v-1.583C6.162 14.441 4 11.973 4 9V4c0-.552.448-1 1-1h3zm11 11c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z"
            />
          </svg>
        </div>
        <template v-if="data.status === 'PENDING'">
          <div class="flex-1 flex flex-col gap-y-1">
            <span class="font-EffraR text-base text-black">
              {{ data.created_at | moment("DD MMMM") }}
            </span>
            <div class="flex flex-col">
              <h1 class="text-black font-EffraM text-lg">
                Rendez-vous
              </h1>
              <span class="font-EffraR text-base text-[#535353]">
                {{ data.body }}
              </span>
            </div>
            <div class="flex justify-end" v-if="data.hasOptions">
              <dok-button
                bg="action"
                custom-class="text-reddok font-EffraM flex items-center gap-x-2 uppercase"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13.5 4.5l-9 9M4.5 4.5l9 9"
                  />
                </svg>
                Refuse
              </dok-button>
              <dok-button
                bg="action"
                custom-class="text-dokGreen-other font-EffraM flex items-center gap-x-2 uppercase"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 4.5l-8.25 8.25L3 9"
                  />
                </svg>
                Accepter
              </dok-button>
            </div>
          </div>
        </template>
        <template v-else-if="data.status === 'ACCEPTED'">
          <div class="flex-1 flex flex-col gap-y-1">
            <span class="font-EffraR text-sm text-black">{{
              data.created_at | moment("DD MMMM")
            }}</span>
            <div class="flex flex-col">
              <h1 class="text-black font-EffraM text-lg">
                Rendez-vous
              </h1>
              <span class="font-EffraR text-base text-[#535353]">
                {{ data.body }}
              </span>
            </div>
          </div>
        </template>
        <template v-else-if="data.status === 'REJECTED'">
          <div class="flex-1 flex flex-col gap-y-1">
            <span class="font-EffraR text-sm text-black">{{
              data.created_at | moment("DD MMMM")
            }}</span>
            <div class="flex flex-col">
              <h1 class="text-black font-EffraM text-lg">
                Rendez-vous
              </h1>
              <span class="font-EffraR text-base text-[#535353]">
                {{ data.body }}
              </span>
            </div>
          </div>
        </template>
      </div>
    </template>

    <!-- --------------------------------------DOCUMENTS-------------------------------------- -->

    <template v-if="data.type === 'DOCUMENT'">
      <div class="flex flex-row gap-x-4">
        <div
          class="w-[32px] h-[32px] bg-rosDok-ultra text-white rounded-full flex items-center justify-center"
          :class="bgByStatus(data.status)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11.667 1.667H5c-.442 0-.866.176-1.178.488-.313.313-.489.737-.489 1.179v13.333c0 .442.176.866.489 1.178.312.313.736.489 1.178.489h10c.442 0 .866-.176 1.179-.488.312-.313.488-.737.488-1.179v-10l-5-5z"
            />
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11.667 1.667v5h5M13.333 10.833H6.667M13.333 14.167H6.667M8.333 7.5H6.667"
            />
          </svg>
        </div>
        <template v-if="data.status === 'PENDING'">
          <div class="flex-1 flex flex-col gap-y-1">
            <span class="font-EffraR text-base text-black">
              {{ data.created_at | moment("DD MMMM") }}
            </span>
            <div class="flex flex-col">
              <h1 class="text-black font-EffraM text-lg">
                Document
              </h1>
              <span class="font-EffraR text-base text-[#535353]">
                {{ data.body }}
              </span>
            </div>
            <div class="flex justify-end" v-if="data.hasOptions">
              <dok-button
                bg="action"
                custom-class="text-reddok font-EffraM flex items-center gap-x-2 uppercase"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13.5 4.5l-9 9M4.5 4.5l9 9"
                  />
                </svg>
                Refuse
              </dok-button>
              <dok-button
                bg="action"
                custom-class="text-dokGreen-other font-EffraM flex items-center gap-x-2 uppercase"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 4.5l-8.25 8.25L3 9"
                  />
                </svg>
                Accepter
              </dok-button>
            </div>
          </div>
        </template>
        <template v-else-if="data.status === 'ACCEPTED'">
          <div class="flex-1 flex flex-col gap-y-1">
            <span class="font-EffraR text-sm text-black">{{
              data.created_at | moment("DD MMMM")
            }}</span>
            <div class="flex flex-col">
              <h1 class="text-black font-EffraM text-lg">
                Document
              </h1>
              <span class="font-EffraR text-base text-[#535353]">
                {{ data.body }}
              </span>
            </div>
          </div>
        </template>
        <template v-else-if="data.status === 'REJECTED'">
          <div class="flex-1 flex flex-col gap-y-1">
            <span class="font-EffraR text-sm text-black">{{
              data.created_at | moment("DD MMMM")
            }}</span>
            <div class="flex flex-col">
              <h1 class="text-black font-EffraM text-lg">
                Document
              </h1>
              <span class="font-EffraR text-base text-[#535353]">
                {{ data.body }}
              </span>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  props: {
    type: {
      type: Number,
      required: false
    },
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    bgByStatus(status) {
      switch (status) {
        case "PENDING":
          return "bg-gray-600";
          break;
        case "ACCEPTED":
          return "bg-green-600";
          break;
        case "REJECTED":
          return "bg-red-600";
          break;
      }
    },
    async onHealthbookChangeStatus({ type, id }) {
      this.$vs.loading();
      await this.$store.dispatch("user/HEALTH_CHANGE_STATUS_PATIENTS", {
        id,
        status: type,
        onData: ({ ok, data }) => {
          if (ok) {
            this.$vs.loading.close();
            this.$nextTick(() => {
              EventBus.$emit("RELOAD_NOTIFICATIONS_PATIENT");
            });
          }
        }
      });
    }
  }
};
</script>
